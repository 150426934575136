<template>
  <div class="content-layout">
    <div class="product-operations">
      <div style="width: 20%">
        <a-button
          v-if="$hasPermissions(['rcs:product_internal_model:create'])"
          type="primary"
          class="add-btn"
          @click="handleAddBtn"
          >新增</a-button
        >
      </div>
      <div class="flex-div flex-center-dev filters">
        <div class="flex-div">
          <a-select v-model="searchParams.filterSelected" style="width: 120px">
            <a-icon slot="suffixIcon" type="smile" />
            <a-select-option v-for="filter in filterOptions" :key="filter.value" :value="filter.value">{{
              filter.label
            }}</a-select-option>
          </a-select>
          <a-input
            style="width: 200px"
            v-model="searchParams.searchKeyword"
            clearable
            @keyup.enter.native="doSearch"
          ></a-input>
        </div>
        <div class="flex-div flex-center-dev">
          <span>更新日期：</span>
          <a-range-picker style="width: 200px" v-model="searchParams.dateSearch" />
        </div>
        <div><a-button class="search-btn" type="primary" @click="doSearch">搜索</a-button></div>
      </div>
    </div>
    <a-table
      size="middle"
      :pagination="false"
      :data-source="tableDate"
      class="product-table"
      rowKey="identifier"
      tableLayout="fixed"
    >
      <a-table-column data-index="identifier" key="identifier" title="产品型号" align="center"></a-table-column>
      <a-table-column data-index="name" key="name" title="产品名称" align="center"></a-table-column>
      <a-table-column data-index="productDtos" key="productDtos" title="关联物模型(数)" align="center">
        <template slot-scope="scope">
          {{ scope.length }}
        </template>
      </a-table-column>
      <a-table-column data-index="lastModificationTime" key="lastModificationTime" title="更新时间" align="center">
        <template slot-scope="scope">{{ scope ? moment(scope).format('YYYY年MM月DD日') : '未知' }}</template>
      </a-table-column>
      <a-table-column data-index="updaterName" key="updaterName" title="更新人" align="center"></a-table-column>
      <a-table-column title="操作" align="center">
        <template slot-scope="scope">
          <a-button
            v-if="$hasPermissions(['rcs:product_internal_model:update'])"
            style="color: #1890ff"
            type="link"
            @click="handleEditBtn(scope)"
            >编辑</a-button
          >
          <a-button
            v-if="$hasPermissions(['rcs:product_internal_model:get'])"
            style="color: #70b603"
            type="link"
            @click="handleDetailBtn(scope)"
            >详情</a-button
          >
          <a-popconfirm
            v-if="$hasPermissions(['rcs:product_internal_model:delete'])"
            title="确认删除?"
            ok-text="是"
            cancel-text="否"
            @confirm="handleDeleteBtn(scope.id)"
          >
            <a-button style="color: #d9001b" type="link">删除</a-button>
          </a-popconfirm>
        </template>
      </a-table-column>
    </a-table>
    <div class="pagination">
      <a-pagination
        :current="pageInfo.page"
        :show-total="(total) => `共 ${pageInfo.totalElements} 条`"
        show-quick-jumper
        show-size-changer
        :total="pageInfo.totalElements"
        @showSizeChange="onShowSizeChange"
        @change="onChange"
        :pageSizeOptions="['10', '20', '30', '40']"
      />
      <br />
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'ProductModelManagement',
  data() {
    return {
      filterOptions: this.$constRepository.productModel.FilterOptions,
      tableDate: [],
      pageInfo: {
        page: 1,
        size: 10,
        totalElements: 0,
        totalPages: 0,
      },
      searchParams: {
        filterSelected: this.$constRepository.productModel.FilterOptions[0].value,
        searchKeyword: '',
        dateSearch: [],
      },
      moment,
    };
  },
  activated() {
    this.loadedPageData();
  },
  methods: {
    async listProductModel() {
      const params = {
        [this.searchParams.filterSelected]: this.searchParams.searchKeyword ?? undefined,
        fromUpdateTime: this.searchParams.dateSearch[0]
          ? this.searchParams.dateSearch[0].startOf('day').valueOf()
          : undefined,
        endUpdateTime: this.searchParams.dateSearch[1]
          ? this.searchParams.dateSearch[1].endOf('day').valueOf()
          : undefined,
        page: this.pageInfo.page - 1,
        size: this.pageInfo.size,
      };
      const response = await this.$apiManager.productModel.getProductModel(params);
      this.tableDate = response.data.elements;
      this.$set(this.pageInfo, 'totalElements', response.data.totalElements);
      this.$set(this.pageInfo, 'totalPages', response.data.totalPages);
      this.$set(this.pageInfo, 'currentPage', response.data.currentPage);
    },
    async loadedPageData() {
      await this.listProductModel();
      if (this.pageInfo.currentPage + 1 > this.pageInfo.totalPages && this.pageInfo.currentPage) {
        this.pageInfo.page = 1;
        await this.listProductModel();
      }
    },
    doSearch() {
      this.pageInfo.page = 1;
      this.loadedPageData();
    },
    handleAddBtn() {
      this.$router.push({ name: 'productModelCreator' });
    },
    handleDeleteBtn(id) {
      this.$apiManager.productModel.deleteProductModel(id).then(() => {
        this.$message.success('删除成功');
        // this.pageInfo.page = 1;
        this.loadedPageData();
      });
    },
    handleEditBtn(model) {
      this.$router.push({ name: 'productModelEditor', params: { id: model.id } });
    },
    handleDetailBtn(model) {
      this.$router.push({ name: 'productModelDetail', params: { id: model.id } });
    },
    onShowSizeChange(current, pageSize) {
      this.pageInfo.page = 1;
      this.$set(this.pageInfo, 'size', pageSize);
      this.loadedPageData();
    },
    onChange(pageNumber) {
      this.$set(this.pageInfo, 'page', pageNumber);
      this.loadedPageData();
    },
  },
};
</script>

<style lang="less" scoped>
.content-layout {
  height: auto;
}

.product-operations {
  display: flex;
  justify-content: space-between;

  .add-btn {
    margin: 10px 0px;
    margin-left: 3%;
  }

  .filters {
    width: 90%;
    justify-content: right;
    div {
      margin-left: 3%;
    }
    .search-btn {
      margin: 10px 0px;
      margin-left: 3%;
    }
  }
}

.product-table {
  margin-top: 1%;
}
.pagination {
  margin-top: 3%;
}
.flex-div {
  display: flex;
}
.flex-center-dev {
  align-items: center;
  justify-content: center;
}
</style>
